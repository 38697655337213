import { Injectable } from '@angular/core';
import { BaseService } from '@mt-ng2/base-service';
import { ISalesOrder } from '../model/interfaces/sales-order';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { IManufacturingOrderDetail } from '../model/interfaces/manufacturing-order-detail';
import { IOrderDetailLine } from '../model/interfaces/order-detail-line';

import { PrintFormsOptions, PrintStockFormsOptions } from '../model/dto/PrintFormsOptions';
import { ISkid } from '../model/interfaces/skid';
import { IOrderPoMapping } from '../shipments/update-po-numbers/update-po-numbers.component';
import { IGeneralLedgerCode } from '@model/interfaces/general-ledger-code';
import { IStockItemSortProps } from '@model/interfaces/custom/stock-item-sort-props';

@Injectable()
export class SalesOrderService extends BaseService<ISalesOrder> {
    private emptyOrderDetailLine: IOrderDetailLine = {
        Basis: null,
        BdlSk: null,
        Caliper: null,
        Count: null,
        Height: null,
        Id: null,
        IsConverting: false,
        IsCutter: false,
        IsPaster: false,
        Length: null,
        NumberOfSkids: null,
        Piles: null,
        PileStainWhiteSide: null,
        Ply: null,
        Quantity: null,
        RuleNumber: null,
        RunnerDirectionTypeId: null,
        Width: null,
    };

    private emptyOrderDetail: IManufacturingOrderDetail = {
        Basis: null,
        BdlSk: null,
        BoardGradeId: null,
        Caliper: null,
        Count: null,
        FinishId: null,
        GeneralLedgerCodeId: null,
        Height: null,
        Id: 0,
        IsBridgeView: null,
        IsCommission: null,
        IsConsignment: null,
        IsConverting: null,
        IsDressStock: null,
        IsFscCertified: null,
        IsLiningDept: null,
        IsShortWay: true,
        Length: null,
        LiningId: null,
        NumberOfSkids: null,
        Piles: null,
        Quantity: null,
        RuleNumber: null,
        ShipOnTypeId: null,
        UnitOfMeasureId: null,
        Width: null,
    };

    constructor(public http: HttpClient) {
        super('/salesorders', http);
    }

    getEmptyOrderDetail(): IManufacturingOrderDetail {
        return { ...this.emptyOrderDetail };
    }
    getEmptyOrderDetailLine(): IOrderDetailLine {
        return { ...this.emptyOrderDetailLine };
    }

    create(salesOrder: ISalesOrder): Observable<number> {
        return this.http.post<number>('/salesorders', salesOrder);
    }

    copy(salesOrderId: number): Observable<number> {
        return this.http.get<number>(`/salesorders/copy/${salesOrderId}`);
    }

    updateWithForeignKeys(salesOrder: ISalesOrder): any {
        // TODO LAS: allow foreign keys in base service
        // Sales order is an object heavily dependent on foreign keys,
        // it cant implement the base service update function
        //  becuase that nulls out the foreign keys
        return this.http.put('/salesorders/' + salesOrder.Id, salesOrder);
    }

    toggleSalesOrderArchived(salesOrderId: number, setArchived: boolean): Observable<void> {
        let headers = new HttpHeaders('Content-Type');
        headers = headers.set('Content-Type', 'application/json'); // Since body is a primitive, the content type is translated as plain text
        return this.http.put<void>(`/salesorders/${salesOrderId}/archive`, setArchived, { headers: headers });
    }

    printForms(salesOrderId: number, printFormsOptions: PrintFormsOptions): any {
        return this.http.post('/salesorders/' + salesOrderId + '/forms', printFormsOptions);
    }

    printStockForms(shipmentId: number, salesOrderId: number, printStockFormsOptions: PrintStockFormsOptions): any {
        return this.http.post('/salesorders/' + salesOrderId + '/' + shipmentId + '/stock-forms', printStockFormsOptions);
    }

    getByOrderNumber(orderNumber: string, excludeDetailsOnExistingTrimSheets: boolean): Observable<ISalesOrder> {
        let params = new HttpParams();
        params = params.append('orderNumber', orderNumber);
        params = params.append('excludeDetailsOnExistingTrimSheets', excludeDetailsOnExistingTrimSheets.toString());
        return this.http.get<ISalesOrder>('/salesorders/bynumber', { params: params });
    }

    getNumberOfSkidsAvailable(salesOrderId: number): Observable<number> {
        return this.http.get<number>('/salesorders/' + salesOrderId.toString() + '/skids/available');
    }

    getSkids(salesOrderId: number): Observable<ISkid[]> {
        return this.http.get<ISkid[]>('/salesorders/' + salesOrderId.toString() + '/skids');
    }

    getGeneralLedgerCodes(): Observable<IGeneralLedgerCode[]> {
        return this.http.get<IGeneralLedgerCode[]>('/salesorders/general-ledger-codes');
    }

    deleteOrderDetailLine(orderDetailLineId: number, salesOrderId: number): Observable<any> {
        return this.http.delete(`/salesorders/${salesOrderId}/order-detail-lines/${orderDetailLineId}`);
    }

    getOrderDetailLines(salesOrderId: number): Observable<any> {
        return this.http.get(`/salesorders/${salesOrderId}/order-detail-lines`);
    }

    printFinalPalletInfo(salesOrderId: number): any {
        return this.http.get('/salesorders/' + salesOrderId + '/pallet-info/final/form');
    }

    printConvertingPalletInfo(salesOrderId: number): any {
        return this.http.get('/salesorders/' + salesOrderId + '/pallet-info/converting/form');
    }

    updatePoNumbers(updatedPOs: IOrderPoMapping[]): Observable<any> {
        return this.http.put('/salesorders/po-numbers', updatedPOs);
    }

    deleteStockOrderLineById(salesOrderId: number, id: number): Observable<void> {
        return this.http.delete<void>('/salesorders/' + salesOrderId + `/stock-order-line/${id}`);
    }

    getProductionDataForOrder(salesOrderId: number): Observable<number> {
        return this.http.get<number>('/salesorders/' + salesOrderId.toString() + '/production-data');
    }

    generateProductionDataForOrder(salesOrderId: number): Observable<any> {
        return this.http.get<any>('/salesorders/' + salesOrderId + '/production-data-summary');
    }

    printedReceiptsExist(salesOrderId: number): Observable<boolean> {
        return this.http.get<boolean>(`/salesorders/${salesOrderId}/printed-receipts-exist`);
    }

    getNumberOfSkidsAvailableToShip(salesOrderId: number): Observable<number> {
        return this.http.get<number>(`/salesorders/${salesOrderId}/skids/available-to-ship`);
    }

    getSkidsTakenFromOrder(salesOrderId: number): Observable<number> {
        return this.http.get<number>(`/salesorders/${salesOrderId}/skids-taken`);
    }

    areDifferentInSize(takeFromSalesOrderId: number, giveToSalesOrderId: number): Observable<boolean> {
        return this.http.get<boolean>(`/salesorders/${takeFromSalesOrderId}/differ-in-size/${giveToSalesOrderId}`);
    }

    sortStockOrderLines(stockOrderLines: any[]): any[] {
        return [...stockOrderLines].sort((a, b) => {
            return this.compareByGradeCaliperSize(
                this.getGradeCaliperSize(a), this.getGradeCaliperSize(b),
            );
        });
    }

    private getGradeCaliperSize(item: any): IStockItemSortProps {
        if (item.StockItem) {
            return {
                Caliper: item.StockItem.CaliperMax,
                Grade: item.StockItem.BoardGrade.Abbreviation,
                Length: item.StockItem.Length,
                Width: item.StockItem.Width,
            };
        } else {
            return {
                Caliper: item.CaliperMax,
                Grade: item.BoardGrade.Abbreviation,
                Length: item.Length,
                Width: item.Width,
            };
        }
    }

    private compareByGradeCaliperSize(a: IStockItemSortProps, b: IStockItemSortProps): number {
        if (a.Grade !== b.Grade) {
            return (a.Grade === 'CHIP' ? '' : a.Grade)
                            .localeCompare(b.Grade === 'CHIP' ? '' : b.Grade);
        }
        if (a.Caliper !== b.Caliper) {
            return a.Caliper - b.Caliper;
        }
        if (a.Width !== b.Width) {
            return a.Width.localeCompare(b.Width);
        }
        return a.Length.localeCompare(b.Length);
    }
}
